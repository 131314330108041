import { Injectable } from '@angular/core';
import { Recommendation } from '../models/recommendation';
import { BehaviorSubject, Observable, Subject, Subscription, map } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/compat/firestore';
import * as moment from 'moment';
import { Post } from '../models/post';
import { User } from '../models/user';
import { Visit } from '../models/visit';
import { Mirador } from '../models/mirador';
import { UsersManagerService } from './users-manager.service';
import { MiradoresService } from './miradores.service';
import { Timestamp } from 'firebase/firestore';
import { UtilService } from './util.service';
import { VisitExperience } from '../models/visit-experience';
import { ExperienceService } from './experience.service';
import { Experience } from '../models/ToursModels';

const TABLE_POSTS = "posts";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private dailyRecommendation = new BehaviorSubject<Recommendation | null>(null);
  public $dailyRecommendation = this.dailyRecommendation.asObservable();

  public dailyRecommendationLoaded = new BehaviorSubject<boolean>(false);
  public $dailyRecommendationLoaded = this.dailyRecommendationLoaded.asObservable();


  private postsProvider = new BehaviorSubject<Post[]>([]);
  public $allPosts = this.postsProvider.asObservable();
  public getAllPostsSubscription: Subscription;
  public lastDoc: Post = null;

  private postsLoaded = new Subject<boolean>();
  public $postsLoaded = this.postsLoaded.asObservable();
  queryngPost: boolean = false;

  constructor(public fireStore: AngularFirestore, public userService: UsersManagerService, public miradorService: MiradoresService, public utilService: UtilService, public experienceService:ExperienceService) {
    const todayStart = moment().startOf('day').toDate();
    const todayEnd = moment().endOf('day').toDate();
    this.loadDailyRecommendation(todayStart, todayEnd);
    this.getPostLazyLoad();
  }


  loadDailyRecommendation(todayStart, todayEnd) {

    this.fireStore.collection<Recommendation>('dailyRecommendations', ref =>
      ref.where('date', '>=', todayStart)
        .where('date', '<=', todayEnd)
        .limit(1)
    ).valueChanges({ idField: '_id' }).subscribe((recomendations) => {

      if (recomendations.length > 0) {
        this.dailyRecommendation.next(recomendations[0]);
        this.dailyRecommendationLoaded.next(true);
      } else {
        const yesterdayStart = moment().subtract(1, 'days').startOf('day').toDate();
        const yesterdayEnd = moment().subtract(1, 'days').endOf('day').toDate();
        this.loadDailyRecommendation(yesterdayStart, yesterdayEnd);
      }

    });
  }

  getDailyRecommendation(): any {
    return this.dailyRecommendation.value;
  }

  getPosts() {
    this.getAllPostsSubscription = this.fireStore.collection<Post>(TABLE_POSTS, ref => ref
      .orderBy("date", 'desc'))
      .valueChanges({ idField: 'postId' }).subscribe((data) => {
        if (data.length) {
          console.log({ data });

          this.postsProvider.next(data);
          this.getAllPostsSubscription.unsubscribe();
          this.postsLoaded.next(true);
        }
      });
  }

  getPostLazyLoad(reset: boolean = false) {
    if(!this.queryngPost) {
      this.queryngPost = true;
      let query = this.fireStore.collection(TABLE_POSTS, ref => {
        let modifiedQuery = ref.orderBy('date', 'desc').limit(30);

        if (this.lastDoc) {
          modifiedQuery = modifiedQuery.startAfter(this.lastDoc.date);
        }

        return modifiedQuery;
      });

      let sust = query.valueChanges({ idField: 'postId' }).subscribe((data: Post[]) => {
        if (data.length > 0) {
          this.lastDoc = data[data.length - 1];
          if(reset) {
            this.postsProvider.next(data);
          }else {
            this.postsProvider.next(this.postsProvider.value.concat(data));
          }

          this.postsLoaded.next(true);
          this.queryngPost = false;
          sust.unsubscribe();
        } else {
          this.queryngPost = false;
          this.postsLoaded.next(false);
          console.log('No hay más documentos.');
          sust.unsubscribe();
        }
      });

    } else {
      console.log("already queryng Post");
    }
  }

  private createVisitPost(user: User, visit: Visit, mirador: Mirador, date = new Date()) {
    let newPost = new Post("NewVisitPost", date, user, visit, mirador);

    this.SetPostData({ ...newPost });
  }

  private createVisitExperiencePost(user: User, visitExperience: VisitExperience, experience: Experience, date = new Date()) {
    let newPost = new Post("NewVisitExperiencePost", date, user, undefined, undefined, visitExperience, experience);
    this.SetPostData({ ...newPost });
  }


  SetPostData(post: Post): Promise<void> {
    const postId = this.fireStore.createId();
    const postRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `${TABLE_POSTS}/${postId}`
    );

    post.postId = postId;

    return postRef.set(post, {
      merge: true,
    }).then(() => {

    });
  }

  createVisitPosts(visits: Visit[]) {
    // throw("Funcion creada para llenar posts, ahora deshabilitada");
    visits.map(visit => {
      let user = visit.userId && this.userService.getUserByUID(visit.userId);
      let mirador = this.miradorService.getMiradorById(visit.miradorId);
      this.createVisitPost(user, visit, mirador, (visit.date as Timestamp).toDate());
    })
  }

  createVisitExperiencePosts(visitExperience: VisitExperience[]) {
    visitExperience.map(visitExperience => {
      let user = this.userService.getUserByUID(visitExperience.userId);
      let experience: Experience = this.experienceService.getExperienceByID(visitExperience.experienceId);
      this.createVisitExperiencePost(user, visitExperience, experience, (visitExperience.date as Timestamp).toDate());
    })
  }

  updatedPostVisitLikesCount(visitId, newCount: number): Promise<void> {
    let affectedPost = this.postsProvider.value.find((post) => {
      return post.visitId == visitId;
    })

    let postRef: AngularFirestoreDocument<Post>;

    if(affectedPost) {
      affectedPost.countLikes = newCount;

      postRef = this.fireStore.doc(
        `${TABLE_POSTS}/${affectedPost.postId}`
      );

      return postRef.update({ "countLikes": newCount });
    }else {
      return this.getPostByVisitId(visitId).then((postRefFound) => {
        return postRefFound.update({ "countLikes": newCount });
       }, (error) => {
        console.warn(error);
        return;
       });
    }

  }

  getPostByVisitId(visitId: string) : Promise<DocumentReference<Post>> {
    return new Promise<DocumentReference<Post>>((resolve, reject) => {
      let docRef = this.fireStore.collection<Post>(TABLE_POSTS, ref => ref.where('visitId', '==', visitId)).get().subscribe((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            resolve(doc.ref);
          });
        } else {
          reject('No se encontro ningun Post asociado a la visita');
        }
      })
    })

  }

  restartLazyLoad() {
    this.lastDoc = null;
    this.getPostLazyLoad(true);
  }

  getPostsRankings(): Observable<Post[]> {
    return new Observable(observer => {
      const subscription = this.fireStore.collection<Post>(TABLE_POSTS, ref => ref
        .where("rankingId", "!=", null))
        .valueChanges({ idField: 'postId' })
        .pipe(
          map((data: Post[]) => {
            return data.sort((a, b) => {
              const dateA = this.getDateValue(a.date);
              const dateB = this.getDateValue(b.date);
              return dateB.getTime() - dateA.getTime();
            });
          })
        )
        .subscribe((sortedData) => {
          if (sortedData.length) {
            observer.next(sortedData);
          }
          subscription.unsubscribe();
          observer.complete();
        });
    });
  }

  private getDateValue(date: Date | Timestamp): Date {
    return (date instanceof Timestamp) ? date.toDate() : date;
  }


}
