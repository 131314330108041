declare const fbq: any; // 1. add an ambient declaration
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth,  } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

import * as firebaseAuth from '@angular/fire/auth';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { MiradorSaved } from '../models/mirador-save';
import { User } from '../models/user';
import { SavedManagerService } from './saved-manager.service';
import { UtilService } from './util.service';
import { ConfirmationResult, FacebookAuthProvider, RecaptchaVerifier, PhoneAuthCredential, PhoneAuthProvider } from '@angular/fire/auth';
import { Visit, VisitGroup, VisitLike } from '../models/visit';
import { VisitsManagerService } from './visits-manager.service';
import { UsersManagerService } from './users-manager.service';
import { FacebookService, LoginOptions, LoginResponse } from 'ngx-facebook';
import { AuthCredential, ConfirmVerificationCodeOptions, FirebaseAuthentication, PhoneVerificationCompletedEvent, PhoneVerificationFailedEvent, SignInResult, SignInWithPhoneNumberOptions } from '@capacitor-firebase/authentication';

import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { AuthStateChange } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import { ToastController } from '@ionic/angular';
import { Timestamp } from 'firebase/firestore';
import { VisitLikesManagerService } from './visitLike-manager.service';
import { VisitExperience, VisitExperienceGroup } from '../models/visit-experience';
import { VisitsExperiencesService } from './visits-experiences.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  globalAuth = firebaseAuth.getAuth();

  GoogleProvider = new firebaseAuth.GoogleAuthProvider();
  FBProvider = new firebaseAuth.FacebookAuthProvider();
  confirmationResult: firebase.default.auth.ConfirmationResult;


  userData: any; // Save logged in user data
  subscriptionData: any[] = []; // Save subscription data

  private establishedSession = new BehaviorSubject<User>(null);
  public $establishedSession = this.establishedSession.asObservable();

  private spectatorSession = new BehaviorSubject<User>(null);
  public $establishedspectatorSession = this.spectatorSession.asObservable();

  private mySavedMiradores = new BehaviorSubject<MiradorSaved[]>([]);
  public $mySavedMiradores = this.mySavedMiradores.asObservable();

  private myMiradoresVisited = new BehaviorSubject<Visit[]>([]);
  public $myMiradoresVisited = this.myMiradoresVisited.asObservable();



  private myLastVisit = new Subject<Visit>();
  public $myLastVisit = this.myLastVisit.asObservable();

  private myMiradoresVisitedGrouped = new BehaviorSubject<VisitGroup[]>([]);
  public $myMiradoresVisitedGrouped = this.myMiradoresVisitedGrouped.asObservable();

  private isMiradoresVisitedGroupedLoaded = new BehaviorSubject<boolean>(false);
  public $isMiradoresVisitedGroupedLoaded = this.isMiradoresVisitedGroupedLoaded.asObservable();

  private currentUserSubject = new BehaviorSubject<User>(null);
  public $currentUserSubject = this.currentUserSubject.asObservable();

  private phoneVerificationCompletedSubject = new BehaviorSubject<PhoneVerificationCompletedEvent>(null);
  public $phoneVerificationCompletedSubject = this.phoneVerificationCompletedSubject.asObservable();

  private phoneVerificationFailedSubject = new BehaviorSubject<PhoneVerificationFailedEvent>(null);
  public $phoneVerificationFailedSubject = this.phoneVerificationFailedSubject.asObservable();

  private phoneCodeSentSubject = new BehaviorSubject<{verificationId: string}>(null);
  public $phoneCodeSentSubject = this.phoneCodeSentSubject.asObservable();

  private myVisitLikes = new BehaviorSubject<VisitLike[]>([]);
  public $myVisitLikes = this.myVisitLikes.asObservable();

  // EXPERIENCES
  private myExperiencesVisited = new BehaviorSubject<VisitExperience[]>([]);
  public $myExperiencesVisited = this.myExperiencesVisited.asObservable();

  private myLastVisitExperience = new Subject<VisitExperience>();
  public $myLastVisitExperience = this.myLastVisitExperience.asObservable();

  private myExperiencesVisitedGrouped = new BehaviorSubject<VisitExperienceGroup[]>([]);
  public $myExperiencesVisitedGrouped = this.myExperiencesVisitedGrouped.asObservable();

  private isExperiencesVisitedGroupedLoaded = new BehaviorSubject<boolean>(false);
  public $isExperiencesVisitedGroupedLoaded = this.isExperiencesVisitedGroupedLoaded.asObservable();

  public get getMySaves() {
    return this.mySavedMiradores.getValue();
  }

  public get getMyMiradoresVisited() {
    return this.myMiradoresVisited.getValue();
  }

  public get getMyMiradoresVisitedGrouped() {
    return this.myMiradoresVisitedGrouped.getValue();
  }

  public get getMyExperiencesVisitedGrouped() {
    return this.myMiradoresVisitedGrouped.getValue();
  }

  savedSubscription: Subscription;
  visitSubscription: Subscription;
  visitLikesSubscription: Subscription;

  constructor(public fireAuth: AngularFireAuth,
    public fireStore: AngularFirestore,
    public router: Router,
    public utilService: UtilService,
    private saveService: SavedManagerService,
    private visitService: VisitsManagerService,
    private visitExperiencesService: VisitsExperiencesService,
    private userService: UsersManagerService,
    public fb: FacebookService,
    private readonly ngZone: NgZone,
    private toastController: ToastController,
    private visitLikesService: VisitLikesManagerService,
    private http: HttpClient
    ) {
    this.globalAuth.useDeviceLanguage();
    this.fb.init({
      appId: '3149842961996504',
      version: 'v8.0'
    });

    FirebaseAuthentication.setLanguageCode({ languageCode: 'en-US' });
    this.FirebaseAuthListeners();

    //this.GetRedirectResultV2();
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.fireAuth.authState.subscribe((user) => {
      if (user) {
        this.spectatorSession.next(null);

        this.userService.$usersLoaded.subscribe((loaded) => {
          if (loaded) {
            let updatedUser = this.userService.getUserByUID(user.uid);
            this.userData = updatedUser;
            this.updateCurrentSession(updatedUser);

            this.SyncMySaves();
            this.SyncMyVisits();
            this.SyncMyVisitsExperiences();
            this.SyncMyVisitLikes();
            // Obtener y almacenar los datos de suscripción
            this.getUserSubscription(user.uid);
          }

        })

      } else {
        localStorage.setItem('user', 'null');
        this.establishedSession.next(null);
        this.mySavedMiradores.next([]);
      }
    });
  }

  getUserSubscription(userId: string) {
    this.fireStore.collection('subscriptions', ref => ref.where('userId', '==', userId))
      .snapshotChanges()
      .subscribe(subscriptions => {
        if (subscriptions.length > 0) {
          this.subscriptionData = subscriptions.map(sub => ({
            ...sub.payload.doc.data() as object,
            subscriptionId: sub.payload.doc.id,
          }));
          this.userData.subscriptions = this.subscriptionData;
          this.updateCurrentSession(this.userData);
        }
      });
  }

  // Obtener los datos de invitaciones para un álbum específico
  getUserInvitationAlbumById(albumId: string) {
    if (this.userData && this.userData.albums) {
      return this.userData.albums.find(album => album.albumId === albumId);
    }
    return null;
  }

  // Actualizar el progreso de invitaciones para un álbum
  updateUserAlbums(user: any) {
    return this.fireStore.collection('users').doc(user.uid).update({
      albums: user.albums
    });
  }

  FirebaseAuthListeners(){

    FirebaseAuthentication.removeAllListeners().then(() => {
      FirebaseAuthentication.addListener('authStateChange', (change: AuthStateChange) => {
        this.ngZone.run(() => {
          this.currentUserSubject.next({...change.user, photoURL: ''});
        });
      });

      FirebaseAuthentication.addListener('phoneVerificationFailed',
        async (event) => {
          this.ngZone.run(() => {
            this.phoneVerificationFailedSubject.next(event);
          });
        }
      );

      FirebaseAuthentication.addListener('phoneCodeSent', async (event) => {
        this.ngZone.run(() => {
          this.phoneCodeSentSubject.next(event);
        });
      });
    });
  }

  updateCurrentSession(updatedUser) {
    localStorage.setItem('user', JSON.stringify(updatedUser));
    console.log({updatedUser});
    this.establishedSession.next(updatedUser);
  }

  // Sign in with Google
  GoogleAuth() {
    // this.AuthLogin(this.GoogleProvider);
  }

  // FBAuth() {

  //   this.FBProvider.addScope("email");
  //   this.AuthLogin(this.FBProvider);
  // }

  async FBAuth(): Promise<void> {
    const loginOptions: LoginOptions = {
      enable_profile_selector: true,
      return_scopes: true,
      scope: 'public_profile,email'
    };

    try {
      this.fb.login(loginOptions).then((response: LoginResponse) => {
        const credential = FacebookAuthProvider.credential(response.authResponse.accessToken);
        this.fireAuth.signInWithCredential(credential).then(() => {

          this.fireAuth.currentUser.then((result) => {

            let email = result.email;
            if (email == undefined || email == null) {
              email = '';
            }
            const userFormatted: User = {
              uid: result.uid,
              email: email ? email : '',
              displayName: result.displayName,
              photoURL: result.photoURL,
              emailVerified: result.emailVerified,
              profileStatus: 'updatedByFacebook',
            };
            this.SetUserData(userFormatted);

          })
        }, this.print);
      }, this.print);



    } catch (error) {
      console.error(error);
    }
  }

  print(obj) {
    console.log(obj);
  }

  getCurrentSession(): User {
    return JSON.parse(localStorage.getItem('user'));
  }


  SyncMySaves() {
    if (this.userData && this.userData.uid) {
      this.savedSubscription = this.saveService.getMySavesMiradores(this.userData).subscribe((mySaves: MiradorSaved[]) => {
        this.mySavedMiradores.next(mySaves);
        this.savedSubscription.unsubscribe();
      })
    }
  }

  SyncMyVisits() {
    if (this.userData && this.userData.uid) {
      this.visitSubscription = this.visitService.getMyMiradoresVisited(this.userData).subscribe((myVisits: Visit[]) => {
        this.myMiradoresVisited.next(myVisits);
        this.myMiradoresVisitedGrouped.next(this.visitService.groupVisitsByMiradorId(myVisits));
        this.isMiradoresVisitedGroupedLoaded.next(true);
        this.myLastVisit.next(this.getMyLastVisit());
        this.visitSubscription.unsubscribe();
      })
    }
  }

  SyncMyVisitsExperiences() {

    if (this.userData && this.userData.uid) {
      this.visitExperiencesService.getMyExperiencesVisited(this.userData).subscribe((myVisits: VisitExperience[]) => {
        this.myExperiencesVisited.next(myVisits);
        this.myExperiencesVisitedGrouped.next(this.visitExperiencesService.groupVisitsByExperienceId(myVisits));
        this.isExperiencesVisitedGroupedLoaded.next(true);
        this.myLastVisitExperience.next(this.getMyLastVisitExperiences());
      })
    }
  }

  SyncMyVisitLikes() {
    if (this.userData && this.userData.uid) {
      this.visitLikesSubscription = this.visitLikesService.getLikesByUser(this.userData).subscribe((myVisitLikes: VisitLike[]) => {

        this.myVisitLikes.next(myVisitLikes);
      })
    }
  }


  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.fireStore.doc(
      `users/${user.uid}`
    );

    return userRef.set(user, {
      merge: true,
    });
  }

  // Sign out
  SignOut() {

   this.fireAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.mySavedMiradores.next([]);
      this.myMiradoresVisited.next([]);
      this.myMiradoresVisitedGrouped.next(this.visitService.groupVisitsByMiradorId([]));
      this.establishedSession.next(null);
      this.spectatorSession.next(null);
      this.router.navigate(['/app/events']);
    });

    if(Capacitor.getPlatform() == 'ios') {
      FirebaseAuthentication.signOut();
    }
  }

  isLogged(): boolean {
    return !!this.establishedSession.getValue();
  }

  loginAsSpectator() {

    this.userData = {
      displayName: "Violet Sunset",
      email: "red.sunset@miradores.com",
      emailVerified: false,
      photoURL: "./assets/sample-images/record-visit/3.png",
      // uid: "FW33GcNgH8eYFgOqFQQvvepWu9W2",
      uid: "FW33GcNgH8eYFgOqFQ",
      profileStatus: 'updatedByUser',
      instagram: "violetsunset"
    };
    this.spectatorSession.next(this.userData);

    // Debug Mode
    /*      this.SyncMyVisits();
         this.establishedSession.next(this.userData);
         localStorage.setItem('user', JSON.stringify(this.userData)); */
  }

  public async recaptcha() {
    return new RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
      callback: (response) => {

      },
      'expired-callback': () => {
      }
    }, this.globalAuth);
  }


  public signInWithPhoneNumberNative(
    options: SignInWithPhoneNumberOptions
  ): Promise<void> {
    return FirebaseAuthentication.signInWithPhoneNumber(options);

  }

  public signInWithPhoneNumber(recaptchaVerifier, phoneNumber: string) {
    return new Promise<any>((resolve, reject) => {

      this.fireAuth.signInWithPhoneNumber(phoneNumber.trim(), recaptchaVerifier)
        .then((confirmationResult: firebase.default.auth.ConfirmationResult) => {
          this.confirmationResult = confirmationResult;
          resolve(confirmationResult);
        }).catch(async (error) => {
          console.error(error);

          const toast = await this.toastController.create({
            message: "Verifica tus datos e intentalo nuevamente",
            duration: 3000,
            color: 'danger',
          });
          toast.present();

          reject(error);
        })
    });
  }


  public async enterVerificationCode(code, displayName = '') {
    return new Promise<any>((resolve, reject) => {
      this.confirmationResult.confirm(code).then(async (result) => {
        this.setUserDateAfterLogin(result, displayName);
        resolve(result.user);
      }).catch((error) => {
        reject(error.message);
      });

    });
  }

  public setUserDateAfterLogin(result, displayName = ''){
    const user: any = result.user;
    let userFormatted: User;

    if (result.additionalUserInfo.isNewUser) { // Se registro
      fbq('track', 'CompleteRegistration');
      userFormatted = {
        uid: user.uid,
        email: '',
        displayName: displayName,
        photoURL: './assets/sample-images/record-visit/3.png',
        emailVerified: false,
        phoneNumber: user.phoneNumber,
        profileStatus: 'notCompleted',
      };
      this.handleWelcomeMessage(user.phoneNumber);
      this.SetUserData(userFormatted);
      this.userService.syncAllUsers();
    }
  }

  public confirmVerificationCode(verificationCode : string, displayName = ''){
    let options: ConfirmVerificationCodeOptions = { verificationCode: verificationCode, verificationId: this.phoneCodeSentSubject.getValue().verificationId }
    this.logInByCredentialAPIjs(PhoneAuthProvider.credential(options.verificationId, options.verificationCode), displayName);
  }

  public logInByCredentialAPIjs(credential: AuthCredential, displayName = '') {
    if(credential) {
      this.fireAuth.signInWithCredential(credential as firebase.default.auth.AuthCredential).then((res: firebase.default.auth.UserCredential) => {

        this.phoneCodeSentSubject.next(null);
        this.fireAuth.currentUser.then((result) => {

           if (res.additionalUserInfo.isNewUser) {
          const userFormatted: User = {
            uid: result.uid,
            email: '',
            displayName: displayName,
            photoURL: './assets/sample-images/record-visit/3.png',
            emailVerified: false,
            phoneNumber: result.phoneNumber,
            profileStatus: 'notCompleted',
          };

          console.log("Creating New User");
          this.handleWelcomeMessage(result.phoneNumber);
          this.SetUserData(userFormatted);
          this.userService.syncAllUsers();

         }
        })
      })
    }

  }

  getMyLastVisitExperiences() {
    return this.myExperiencesVisited.value.sort((a, b) => {
      let aDate = (a.date as Timestamp).toMillis();
      let bDate = (b.date as Timestamp).toMillis();
      return aDate - bDate;
    })[this.myExperiencesVisited.value.length - 1];
  }

  getMyLastVisit() {
    return this.myMiradoresVisited.value.sort((a, b) => {
      let aDate = (a.date as Timestamp).toMillis();
      let bDate = (b.date as Timestamp).toMillis();
      return aDate - bDate;
    })[this.myMiradoresVisited.value.length - 1];
  }

  generateOtpWhatsapp(phoneNumber:string): Observable<any> {
    return this.http.post<any>(
    `${environment.apiBackendMiradores}/auth/generate-otp-whatsapp`,
      {phoneNumber}
    );
  }

  signInWithCodeWhatsapp(body:{phoneNumber:string, otp: string, platform?:string}): Observable<any> {
      body.platform = Capacitor.isNativePlatform() ? 'app' : 'web';
      return this.http.post<any>(
      `${environment.apiBackendMiradores}/auth/verify-otp-whatsapp`,
        body
      );
  }

  sendWelcomeMessageRegistrationPhone(phoneNumber: string): Observable<any> {
    const platform = Capacitor.isNativePlatform() ? 'app' : 'web';
    return this.http.post(`${environment.apiBackendMiradores}/auth/send-welcome-message-registration-phone`, { phoneNumber, platform });
  }

  private handleWelcomeMessage(phone: string) {
    this.sendWelcomeMessageRegistrationPhone(phone)
    .subscribe({
      next: (data) => {
        // console.log(data);
      },
      error: (error) => {
        // console.log(error);
      }
    });
  }
}
