import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigService {
  remoteConfig: firebase.remoteConfig.RemoteConfig;

  constructor(private alertController: AlertController) {
    this.remoteConfig = firebase.remoteConfig();
    //por defecto 12 horas(43200000) recomienda Firebase - disminuir el tiempo para desarrollo
    this.remoteConfig.settings.minimumFetchIntervalMillis = 43200000;
  }

  async checkForUpdate() {
    if (!Capacitor.isNativePlatform()) {
      // Evitar ejecutar en entorno web
      return;
    }

    try {
      await this.remoteConfig.fetchAndActivate();
      const appInfo = await App.getInfo();
      const currentVersion = appInfo.version;

      const remoteVersion = await this.remoteConfig.getString(
        'app_version'
      );

      // console.log('versionCurrent:', currentVersion,'verionRemote:', remoteVersion);

      if (this.isUpdateAvailable(remoteVersion, currentVersion)) {
        const platform = Capacitor.getPlatform();
        await this.notifyUserAboutUpdate(platform);
      }
    } catch (error) {
      console.error(
        'Error al obtener y activar la configuración remota:',
        error
      );
    }
  }

  isUpdateAvailable(remoteVersion: string, currentVersion: string): boolean {
    const remoteParts = remoteVersion.split('.').map(Number);
    const currentParts = currentVersion.split('.').map(Number);

    // Encuentra la longitud mínima de ambas versiones
    const minLength = Math.min(remoteParts.length, currentParts.length);

    // Compara cada parte de la versión
    for (let i = 0; i < minLength; i++) {
      if (remoteParts[i] > currentParts[i]) {
        // La versión remota tiene una parte mayor, es una actualización disponible
        return true;
      } else if (remoteParts[i] < currentParts[i]) {
        // La versión remota tiene una parte menor, no es una actualización disponible
        return false;
      }
      // Si las partes son iguales, continúa con la siguiente parte
    }

    // Si todas las partes son iguales hasta ahora, verifica si la versión remota tiene más partes
    return remoteParts.length > currentParts.length;
  }

  private async notifyUserAboutUpdate(platform: string) {
    try {
      console.log('¡Hay una nueva versión disponible!');

      const alert = await this.alertController.create({
        header: 'Actualización disponible',
        subHeader: '¡Hay una nueva versión de la aplicación!',
        buttons: [
          {
            text: 'Actualizar',
            handler: () => {
              // Abrir la tienda de aplicaciones correspondiente
              const isIos = platform === 'ios';
              const appStoreUrl =
                'https://apps.apple.com/ca/app/miradores-medell%C3%ADn/id6449554555';
              const playStoreUrl =
                'https://play.google.com/store/apps/details?id=co.miradores.med&hl=en_US';

              const url = isIos ? appStoreUrl : playStoreUrl;

              if (Capacitor.isNativePlatform()) {
                // En entornos nativos (Capacitor), abrirá la tienda de aplicaciones directamente
                window.open(url, '_system');
              } else {
                // En entornos web, redirigir directamente al enlace
                window.location.href = url;
              }
            },
          },
        ],
        backdropDismiss: false
      });

      await alert.present();
    } catch (error) {
      console.error('Error al mostrar la alerta:', error);
    }
  }
}
